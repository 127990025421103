import { ProColumns } from '@ant-design/pro-table';
import { Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import {
  Actions,
  GTable,
  ShareDocumentModal,
  SingleColumnTags,
  SupportModal,
  TitleBar,
} from 'components';
import { useModalVisibility } from 'hooks';
import SharedTag from 'pages/Documents/components';
import { FilterArrayProp } from 'pages/Network/typings';
import pluralize from 'pluralize';
import React, { FC, Key, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useAccountSubscription } from 'services/api';
import { featureFlag, getDocTypeFilters, supportEmailLink } from 'utils';
import EmptyStateDocuments from './EmptyStateDocuments';
import { useDocumentActions, useDocumentFilters } from './hooks';
import { DocumentItem, DocumentTitleProp } from './typings';

type ActionType = {
  t: TFunction<'pages', 'template_attributes.documents'>;
  selectedRowKeys?: Key[];
};

const mainActionItems = ({ selectedRowKeys, t }: ActionType): ItemType[] => [
  {
    key: 'add_document',
    label: t('actions.add_document'),
    disabled: false,
  },
  {
    key: 'download',
    label: t('actions.download'),
    disabled: !selectedRowKeys?.length,
  },
  {
    key: 'share',
    label: t('actions.share'),
    disabled: !selectedRowKeys?.length,
  },
  {
    key: 'archive',
    label: t('actions.archive'),
    disabled: !selectedRowKeys?.length,
  },
];
const actionItems = ({ t }: ActionType): ItemType[] => [
  {
    key: 'view',
    label: t('actions.view'),
    disabled: false,
  },
  {
    key: 'download',
    label: t('actions.download'),
    disabled: false,
  },
  {
    key: 'share',
    label: t('actions.share'),
    disabled: false,
  },
  {
    key: 'copy_url',
    label: t('actions.copy_url'),
    disabled: false,
  },
  {
    key: 'archive',
    label: t('actions.archive'),
    disabled: false,
  },
];

type ColumnsType = {
  nameSearchfilter: ProColumns;
  handleActionsMenuClick: (actionItemKey: string, actionPayload?: DocumentItem) => void;
  coreCompanyFilter: Array<FilterArrayProp>;
  tradePartnersFilter: Array<FilterArrayProp>;
  locationsFilter: Array<FilterArrayProp>;
  productsFilter: Array<FilterArrayProp>;
  t: TFunction<'pages', 'template_attributes.documents'>;
};

const columns = ({
  nameSearchfilter,
  handleActionsMenuClick,
  coreCompanyFilter,
  tradePartnersFilter,
  locationsFilter,
  productsFilter,
  t,
}: ColumnsType): ProColumns<DocumentItem>[] => [
  {
    title: t('columns.document_name'),
    dataIndex: 'title',
    fixed: 'left',
    hideInSetting: true,
    render: (text, record) => (
      <Link
        className="text-link"
        to={{
          pathname: String(record?.id || '1'),
        }}
      >
        {text}
      </Link>
    ),
    ellipsis: true,
    width: 230,
    ...nameSearchfilter,
  },
  {
    title: t('columns.document_type.title'),
    dataIndex: 'documentType',
    filters: getDocTypeFilters(),
    ellipsis: true,
    width: 150,
  },
  {
    title: t('columns.company'),
    dataIndex: 'tradepartners',
    filters: tradePartnersFilter,
    filterSearch: true,
    ellipsis: true,
    render: (text, record) => (
      <SingleColumnTags
        values={
          record?.associations
            ?.filter((item) => item?.type === 'Company')
            ?.map((item) => item?.label || '') || []
        }
        columnTitle={pluralize(t('columns.company'), 2)}
        modalTitle={record?.title || ''}
        colTableTitle={t('columns.company')}
      />
    ),
    width: 250,
  },
  {
    title: t('columns.location'),
    dataIndex: 'locations',
    filters: locationsFilter,
    filterSearch: true,
    ellipsis: true,
    render: (text, record) => (
      <SingleColumnTags
        values={
          record?.associations
            ?.filter((item) => item?.type === 'Location')
            ?.map((item) => item?.label || '') || []
        }
        columnTitle={pluralize(t('columns.location'), 2)}
        modalTitle={record?.title || ''}
        colTableTitle={t('columns.location')}
      />
    ),
    width: 250,
  },
  {
    title: t('columns.product'),
    dataIndex: 'products',
    filters: productsFilter,
    ellipsis: true,
    filterSearch: true,
    render: (text, record) => (
      <SingleColumnTags
        values={
          record?.associations
            ?.filter((item) => item?.type === 'Product')
            ?.map((item) => item?.label || '') || []
        }
        columnTitle={pluralize(t('columns.product'), 2)}
        modalTitle={record?.title || ''}
        colTableTitle={t('columns.product')}
      />
    ),
    width: 250,
  },
  {
    title: t('columns.shared.title'),
    dataIndex: 'shared',
    render: (text, record) => <SharedTag sharedWith={record.sharedWith} />,
    filters: coreCompanyFilter,
    filterSearch: true,
    width: 230,
    ellipsis: true,
  },
  {
    title: t('columns.recent'),
    dataIndex: 'recentActivity',
    ellipsis: true,
    width: 150,
  },
  {
    title: t('columns.expiration'),
    dataIndex: 'expirationDate',
    ellipsis: true,
    width: 150,
  },
  {
    title: t('columns.blockchain.title'),
    dataIndex: 'blockchain',
    ellipsis: true,
    width: 150,
  },
  {
    title: t('columns.visibility.title'),
    dataIndex: 'privacy',
    ellipsis: true,
    width: 150,
    render: (text, record) =>
      t(`columns.visibility.filters.${String(record?.privacy)?.toLowerCase()}`),
    filters: [
      {
        text: t('columns.visibility.filters.private'),
        value: 'Private',
      },
      {
        text: t('columns.visibility.filters.public'),
        value: 'Public',
      },
    ],
  },
  {
    title: t('columns.url'),
    dataIndex: 'url',
    width: 150,
    ellipsis: true,
    copyable: true,
  },
  {
    dataIndex: 'actions',
    hideInSetting: true,
    render: (text, record) => (
      <Actions
        className="actions"
        buttonProps={{
          ghost: true,
          size: 'small',
        }}
        items={actionItems({ t })}
        onClick={handleActionsMenuClick}
        actionPayload={record}
      />
    ),
    fixed: 'right',
    width: 150,
  },
];

const DocumentTitle: FC<DocumentTitleProp> = ({ total, onActionItemClick, selectedRowKeys }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.documents' });

  return (
    <TitleBar
      title={<Typography.Title level={4}>{t('table_title', { total })}</Typography.Title>}
      extra={
        selectedRowKeys.length > 0 && (
          <Typography.Title level={5} className="selectiontext">
            {t('selected', {
              count: selectedRowKeys.length,
              title: pluralize(t('selected_title'), selectedRowKeys.length),
            })}
          </Typography.Title>
        )
      }
      actionItems={mainActionItems({ selectedRowKeys, t })}
      onActionItemClick={onActionItemClick}
    />
  );
};

const Documents = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.documents' });
  const navigate = useNavigate();
  const { data: account } = useAccountSubscription();
  const subscription = useMemo(() => account?.subscriptionDetails || {}, [account]);
  const supportModal = useModalVisibility(false);
  const {
    onDownloadDocumentMultiple,
    onArchiveDocumentMultiple,
    onShareDocumentMultiple,
    selectedRowKeys,
    onRowSelectionChange,
    onDownloadDocument,
    copyURL,
    onArchiveDocument,
    onShareDocument,
    shareModal,
    resetRowSelection,
  } = useDocumentActions();

  const {
    params,
    records,
    totalItems,
    netItemCount,
    isLoading,
    filters: [nameSearchfilter],
    onTableChange,
    coreCompanyFilter,
    tradePartnersFilter,
    locationsFilter,
    productsFilter,
    setParams,
  } = useDocumentFilters();

  const onActionItemClick = async (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'add_document':
        if (
          featureFlag?.planCheck
            ? // @ts-ignore
              (totalItems || 0) < (subscription?.totalDocuments || 0) ||
              !Object.prototype.hasOwnProperty.call(subscription, 'totalDocuments')
            : true
        ) {
          navigate({ pathname: `add` });
        } else {
          supportModal?.show();
        }
        break;
      case 'download':
        onDownloadDocumentMultiple(selectedRowKeys);
        break;
      case 'share':
        onShareDocumentMultiple(selectedRowKeys as Array<string>);
        break;
      case 'archive':
        await onArchiveDocumentMultiple(selectedRowKeys);
        setParams({
          ...params,
          pageNumber: 1,
        });
        break;
      default:
        break;
    }
  };

  const handleActionsMenuClick = async (actionItemKey: string, actionPayload?: DocumentItem) => {
    switch (actionItemKey) {
      case 'view':
        navigate(String(actionPayload?.id || 0));
        break;
      case 'download':
        onDownloadDocument(actionPayload);
        break;
      case 'share':
        onShareDocument(actionPayload);
        break;
      case 'copy_url':
        copyURL(actionPayload);
        break;
      case 'archive':
        onArchiveDocument(actionPayload);
        setParams({
          ...params,
          pageNumber: 1,
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      <ShareDocumentModal modal={shareModal} cleanUpAfterShare={resetRowSelection} />
      <GTable<DocumentItem>
        columns={
          netItemCount > 0
            ? columns({
                nameSearchfilter,
                handleActionsMenuClick,
                coreCompanyFilter,
                tradePartnersFilter,
                locationsFilter,
                productsFilter,
                t,
              })
            : undefined
        }
        headerTitle={
          <DocumentTitle
            total={totalItems}
            onActionItemClick={onActionItemClick}
            shareModal={shareModal}
            selectedRowKeys={selectedRowKeys}
          />
        }
        value={records}
        enableRowSelection
        loading={isLoading}
        scroll={netItemCount > 0 ? { x: 2000, y: '75vh' } : undefined}
        pagination={{
          defaultPageSize: params.pageSize,
          total: totalItems,
          current: params.pageNumber,
          showTotal: (total, range) =>
            t('doc_table_pagi', { range0: range[0], range1: range[1], total }),
        }}
        emptyContainer={!netItemCount ? <EmptyStateDocuments t={t} /> : undefined}
        showHeader={netItemCount > 0}
        rowSelection={{ selectedRowKeys, onChange: onRowSelectionChange }}
        columnsState={{
          persistenceKey: 'pages-documents',
        }}
        // @ts-ignore
        onTableChange={onTableChange}
      />
      <SupportModal
        modal={supportModal}
        description={
          <div>
            {t('upgrade_modal_description')}&nbsp;
            <a href={supportEmailLink} type="mail">
              {t('support_anchor_email_title')}
            </a>
            &nbsp;
            {t('upgrade_modal_desc2')}
          </div>
        }
      />
      {/* Outlet used to render the drawers */}
      <Outlet />
    </>
  );
};

export default React.memo(Documents);
